import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

function RedirectNotificationComponent() {
    var showRedirectNotification =
        localStorage.getItem("showRedirectNotification") === null
            ? false
            : localStorage.getItem("showRedirectNotification") === "true";
    const [show, setShow] = useState(showRedirectNotification);

    const redirectAfterLoginURLEncoded =
        localStorage.getItem("redirectAfterLogin");
    var redirectAfterLogin = redirectAfterLoginURLEncoded
        ? decodeURIComponent(redirectAfterLoginURLEncoded)
        : null;

    return (
        <Row>
            <Col>
                <Alert variant="info" show={show}>
                    <Alert.Heading>You're almost there...</Alert.Heading>
                    You will be redirected back to{" "}
                    <strong>{redirectAfterLogin}</strong> after sigining in.
                    <hr />
                    <Button
                        variant="info"
                        onClick={() => {
                            setShow(false);
                            localStorage.setItem(
                                "showRedirectNotification",
                                false
                            );
                        }}
                    >
                        Dismiss
                    </Button>
                </Alert>
            </Col>
        </Row>
    );
}

export default RedirectNotificationComponent;
