import React, { useState } from "react";
import useAuth from "../components/AuthContext";
import { Link, useNavigate } from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Button } from "react-bootstrap";

function NavbarComponent() {
    const navigate = useNavigate();
    const { loadingCurrentUser, currentUser, logout } = useAuth(); // isauth() returns true or false based on localStorage

    const handleLogout = () => {
        logout();
        navigate("/");
    };

    if (loadingCurrentUser) {
        return <div>Loading...</div>;
    }

    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href="/">Giving the Distance</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {!loadingCurrentUser && currentUser ? (
                            <Nav.Link href="/home">My Profile</Nav.Link>
                        ) : undefined}
                    </Nav>
                    <Nav>
                        {!loadingCurrentUser && currentUser ? (
                            <Button
                                variant="outline-secondary"
                                onClick={handleLogout}
                            >
                                Sign out
                            </Button>
                        ) : (
                            <Link to="/signin">
                                <Button variant="outline-secondary">
                                    Sign in
                                </Button>
                            </Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavbarComponent;
