import { createContext, useContext, useState, useEffect } from "react";
import { getCurrentUser, signIn, signOut } from "../utils/cognitoAuth";
import { Hub } from "aws-amplify";

const authContext = createContext();

function useProvideAuth() {
    const [loadingCurrentUser, setLoadingCurrentUser] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "signUp":
                    console.log("User registered");
                    break;
                case "cognitoHostedUI":
                case "signIn":
                    getCurrentUser()
                        .then((userData) => {
                            setCurrentUser(userData);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    break;
                case "signOut":
                    setCurrentUser(null);
                    break;
                case "signIn_failure":
                case "cognitoHostedUI_failure":
                    console.log("Sign in failure", data);
                    break;
                default:
            }
        });

        getCurrentUser()
            .then((userData) => {
                setLoadingCurrentUser(false);
                return setCurrentUser(userData);
            })
            .catch((err) => {
                setLoadingCurrentUser(false);
                console.log(err);
            });
    }, []);

    return {
        loadingCurrentUser,
        currentUser,
        logout() {
            return signOut();
        },
    };
}

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    console.log(auth);
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function useAuth() {
    return useContext(authContext);
}
