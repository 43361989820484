import React, { useState } from "react";
import { Helmet } from "react-helmet";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

function LoadingComponent() {
    return (
        <Row>
            <Col>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Giving the Distance | Loading...</title>
                </Helmet>
                <Alert variant="light">
                    <Alert.Heading>Loading...</Alert.Heading>
                    <p>This may take a few seconds. Please be patient.</p>
                    <Spinner animation="border" role="status"></Spinner>
                </Alert>
            </Col>
        </Row>
    );
}

export default LoadingComponent;
