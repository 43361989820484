import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { signIn } from "../../../utils/cognitoAuth";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import RedirectNotificationComponent from "../../../components/RedirectNotificationComponent";

import "./SignIn.css";

function SignIn() {
    const navigate = useNavigate();

    const [signInEmail, setSignInEmail] = useState("");
    const [signInPassword, setSignInPassword] = useState("");
    const [signInError, setSignInError] = useState("");

    const handleSubmit = async (event) => {
        console.log(signInEmail, signInPassword);
        event.preventDefault();
        event.stopPropagation();

        const signInResult = await signIn(signInEmail, signInPassword)
            .then((result) => {
                console.log(result);
                const redirectAfterLoginURLEncoded =
                    localStorage.getItem("redirectAfterLogin");
                const redirectAfterLogin = decodeURIComponent(
                    redirectAfterLoginURLEncoded
                );
                localStorage.removeItem("redirectAfterLogin");
                localStorage.removeItem("showRedirectNotification");
                navigate(
                    redirectAfterLogin !== "null" && redirectAfterLogin !== null
                        ? redirectAfterLogin
                        : "/home"
                );
            })
            .catch((err) => {
                console.log(err);
                if (
                    err?.name === "AuthError" ||
                    err?.name === "NotAuthorizedException" ||
                    err?.name === "UserNotFoundException" ||
                    err?.name === "UserNotConfirmedException"
                ) {
                    setSignInError(err.message.replace("Username", "Email"));
                }
            });
    };

    return (
        <div className="SignIn">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Giving the Distance | Sign in</title>
            </Helmet>
            <Container>
                <Row style={{ height: 12 }}></Row>
                <h3>Sign on in</h3>
                <RedirectNotificationComponent />
                <Row style={{ height: 12 }}></Row>
                <Row>
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationCustomEmail"
                            >
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="address@example.com"
                                    defaultValue=""
                                    onChange={(event) => {
                                        setSignInEmail(event.target.value);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationCustom02"
                            >
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    placeholder=""
                                    defaultValue=""
                                    onChange={(event) => {
                                        setSignInPassword(event.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Row>
                        <Button type="submit">Sign in</Button>
                        <Row style={{ height: 12 }}></Row>
                        <Row className="mb-3">
                            <Link to="/signup">New user? Sign up here!</Link>
                        </Row>
                        <Row className="mb-3">
                            <Link to="/forgotpassword">
                                Forgot your password? We got you.
                            </Link>
                        </Row>

                        {signInError ? (
                            <>
                                <Row style={{ height: 12 }}></Row>
                                <Row className="mb-3">
                                    <Alert variant={"danger"}>
                                        {signInError}
                                    </Alert>
                                </Row>
                            </>
                        ) : null}
                    </Form>
                </Row>
            </Container>
        </div>
    );
}

export default SignIn;
