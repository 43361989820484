import { AxiosError } from "axios";
import { axiosWithAuth } from "../axiosWithAuth";
import { getCurrentUser } from "../cognitoAuth";

export async function getSponsorship(sponsoredAthleteUserID) {
    var currentUser = await getCurrentUser();
    if (currentUser === null) {
        return null;
    }
    if (currentUser instanceof Error) {
        return currentUser;
    }

    const sponsorshipID = currentUser.sub + "_" + sponsoredAthleteUserID;
    return axiosWithAuth("get", `/sponsorship/${sponsorshipID}`)
        .then(({ data }) => {
            console.log(data);
            return data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function getSponsorshipRate(sponsoredAthleteUserID) {
    var currentUser = await getCurrentUser();
    if (currentUser === null) {
        return null;
    }
    if (currentUser instanceof Error) {
        return currentUser;
    }

    const sponsorshipID = currentUser.sub + "_" + sponsoredAthleteUserID;
    return axiosWithAuth("get", `/sponsorship/${sponsorshipID}/rate`)
        .then(({ data }) => {
            console.log(data);
            return data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function getSetupSponsorshipOverview(sponsoredAthleteUserID) {
    var currentUser = await getCurrentUser();
    if (currentUser === null) {
        return null;
    }
    if (currentUser instanceof Error) {
        return currentUser;
    }

    const sponsorshipID = currentUser.sub + "_" + sponsoredAthleteUserID;
    return axiosWithAuth(
        "get",
        `/sponsorship/${sponsorshipID}/setupsponsorshipoverview`
    )
        .then(({ data }) => {
            console.log(data);
            return data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function createSponsorship(sponsoredAthleteUserID, rate) {
    var currentUser = await getCurrentUser();
    if (currentUser === null) {
        return null;
    }
    if (currentUser instanceof Error) {
        return currentUser;
    }

    return axiosWithAuth("post", "/sponsorship", {
        sponsorUserID: currentUser.sub,
        sponsoredAthleteUserID,
        rate,
    })
        .then(({ data }) => {
            console.log(data);
            return data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function cancelSponsorship(sponsoredAthleteUserID) {
    var currentUser = await getCurrentUser();
    if (currentUser === null) {
        return null;
    }
    if (currentUser instanceof Error) {
        return currentUser;
    }

    const sponsorshipID = currentUser.sub + "_" + sponsoredAthleteUserID;
    return axiosWithAuth("patch", `/sponsorship/${sponsorshipID}/cancel`)
        .then(({ data }) => {
            console.log(data);
            return data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function addSponsorStripePaymentMethodID(stripePaymentMethodID) {
    return axiosWithAuth("post", "/paymentmethod", {
        stripePaymentMethodID,
    })
        .then(({ data }) => {
            console.log(data);
            return data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}
