import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
    useStripe,
    useElements,
    PaymentElement,
} from "@stripe/react-stripe-js";

import { getStripeSetupIntentClientSecret } from "../../utils/db/Stripe";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import "./StripePaymentMethodEntry.css";
import LoadingComponent from "../../components/LoadingComponent";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
    "pk_test_51Kgd1EBp2tm6vBrPDgsgrHf15ibavqmTHIQQ54yH5D54t12tZG5DxGufNibAeRaPZYxdUKYpbyp8uQAgXV6aqI7400G8XJ3py5"
);

const SetupForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url:
                    "https://beta.app.givingthedistance.org/payment/setupconfirmation",
            },
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            console.log("error", error);
            setErrorMessage(error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <Row style={{ height: 12 }}></Row>
            <Button disabled={!stripe} type="submit">
                Submit
            </Button>
            {/* Show error message to your customers */}
            {errorMessage && (
                <>
                    <Row style={{ height: 12 }}></Row>
                    <Alert variant="warning">{errorMessage}</Alert>
                </>
            )}
        </form>
    );
};

function StripePaymentMethodEntry() {
    const [stripeSetupIntentClientSecret, setStripeSetupIntentClientSecret] =
        useState(null);

    useEffect(() => {
        console.log("StripePaymentMethodEntry");
        getStripeSetupIntentClientSecret().then((result) => {
            // console.log(result);
            if (result instanceof Error) {
                console.log("Error! TODO: Parse me :)", result);
            }
            setStripeSetupIntentClientSecret(result.client_secret);
        });
    }, []);

    const options = {
        clientSecret: stripeSetupIntentClientSecret,
        // Fully customizable with appearance API.
        appearance: {
            /*...*/
        },
    };

    return (
        <div className="StripePaymentMethodEntry">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Giving the Distance | Payment Method Entry</title>
            </Helmet>
            <Container>
                <Row style={{ height: 12 }}></Row>
                <h3>Stripe Payment Method Entry</h3>
                <Col>
                    <Alert variant="warning">
                        You are using beta mode. Please do not enter real credit
                        card information. It would be safe to do so, but it
                        won't work. Instead,{" "}
                        <strong>use the test card number</strong> below and fill
                        out the other fields with whatever values you want.
                        <br />
                        Card number: <strong>4242 4242 4242 4242</strong>
                    </Alert>
                    <Row style={{ height: 12 }}></Row>
                </Col>
                <Alert variant="info">
                    <Alert.Heading>
                        Wait, I need to enter my credit card?
                    </Alert.Heading>
                    Yes, we use{" "}
                    <strong>
                        <a href="https://stripe.com/">Stripe</a>
                    </strong>{" "}
                    to securely store your credit card information. We don't
                    store your credit card information on our servers. Your
                    credit card information is only used to process your
                    donation whenever the athletes that you sponsor complete
                    activities. Once you have entered your credit card
                    information, you will be able to create sponsorships for
                    athletes according to specific settings.
                </Alert>
                <Row style={{ height: 12 }}></Row>
                {stripeSetupIntentClientSecret ? (
                    <Elements stripe={stripePromise} options={options}>
                        <SetupForm />
                    </Elements>
                ) : (
                    <LoadingComponent />
                )}
            </Container>
        </div>
    );
}

export default StripePaymentMethodEntry;
