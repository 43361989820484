import { Link, Routes, Route, BrowserRouter } from "react-router-dom";
import Container from "react-bootstrap/Container";

import { ProvideAuth } from "./components/AuthContext";
import { PrivateRoute } from "./components/PrivateRoute";
import { AuthenticatedRoute } from "./components/AuthenticatedRoute";

// Components
import NavbarComponent from "./components/NavbarComponent";

// Public Screens
import Landing from "./screens/landing/Landing";
import Unauthorized from "./screens/unauthorized/Unauthorized";

// Auth Screens
import Profile from "./screens/profile/Profile";
import SignIn from "./screens/auth/signin/SignIn";
import SignUp from "./screens/auth/signup/SignUp";
import SignUpVerify from "./screens/auth/signupverify/SignUpVerify";
import ForgotPassword from "./screens/auth/forgotpassword/ForgotPassword";
import ForgotPasswordVerify from "./screens/auth/forgotpasswordverify/ForgotPasswordVerify";

// Private Screens
import StravaExchangeToken from "./screens/stravaexchangetoken/StravaExchangeToken";
import ConnectStrava from "./screens/connectstrava/ConnectStrava";
import StripePaymentMethodEntry from "./screens/stripepaymentmethodentry/StripePaymentMethodEntry";
import StripePaymentMethodEntryConfirmation from "./screens/stripepaymentmethodentryconfirmation/StripePaymentMethodEntryConfirmation";
import SetupSponsorship from "./screens/setupsponsorship/SetupSponsorship";
import Home from "./screens/home/Home";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
    return (
        <div className="App">
            <ProvideAuth>
                <BrowserRouter>
                    <NavbarComponent />
                    <Container>
                        <Routes>
                            <Route path="/" element={<Landing />} />
                            <Route
                                path="/unauthorized"
                                element={<Unauthorized />}
                            />
                            <Route
                                path="/signin"
                                element={
                                    <AuthenticatedRoute>
                                        <SignIn />
                                    </AuthenticatedRoute>
                                }
                            />
                            <Route
                                path="/signup"
                                element={
                                    <AuthenticatedRoute>
                                        <SignUp />
                                    </AuthenticatedRoute>
                                }
                            />
                            <Route
                                path="/signupverify"
                                element={
                                    <AuthenticatedRoute>
                                        <SignUpVerify />
                                    </AuthenticatedRoute>
                                }
                            />
                            <Route
                                path="/forgotpassword"
                                element={
                                    <AuthenticatedRoute>
                                        <ForgotPassword />
                                    </AuthenticatedRoute>
                                }
                            />
                            <Route
                                path="/forgotpasswordverify"
                                element={
                                    <AuthenticatedRoute>
                                        <ForgotPasswordVerify />
                                    </AuthenticatedRoute>
                                }
                            />
                            <Route
                                path="/home"
                                element={
                                    <PrivateRoute>
                                        <Home />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/profile/:userId"
                                element={
                                    <PrivateRoute>
                                        <Profile />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/strava/connect"
                                element={
                                    <PrivateRoute>
                                        <ConnectStrava />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/strava/exchange_token"
                                element={
                                    <PrivateRoute>
                                        <StravaExchangeToken />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/payment/setup"
                                element={
                                    <PrivateRoute>
                                        <StripePaymentMethodEntry />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/payment/setupconfirmation"
                                element={
                                    <PrivateRoute>
                                        <StripePaymentMethodEntryConfirmation />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/user/:userId/setupsponsorship"
                                element={
                                    <PrivateRoute>
                                        <SetupSponsorship />
                                    </PrivateRoute>
                                }
                            />
                        </Routes>
                    </Container>
                </BrowserRouter>
            </ProvideAuth>
        </div>
    );
}

export default App;
