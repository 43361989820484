import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./Unauthorized.css";

function Unauthorized() {
    return (
        <div className="Unauthorized">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Giving the Distance | Wrong way...</title>
            </Helmet>
            <Container>
                <Row style={{ height: 12 }}></Row>
                <h3>Nothing to see here!</h3>
                <Row style={{ height: 12 }}></Row>
                <Row>
                    <Col>
                        The page you are trying to view requires you to be
                        signed in.
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Link to={"/signin"}>Let's get you signed in!</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Unauthorized;
