import React, { useState } from "react";
import { Helmet } from "react-helmet";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";

import "./Landing.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Landing() {
    return (
        <Row>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Giving the Distance | Home</title>
            </Helmet>
            <Col>
                <div id="landing-hero">
                    <div id="landing-hero-background"></div>
                    <div id="landing-hero-text">
                        <div id="landing-hero-title">Giving the Distance</div>
                        <Row style={{ height: 24 }}></Row>
                        <div id="landing-hero-subtitle">
                            Get out there for you.
                            <br />
                            Get out there for good.
                        </div>
                        <Row style={{ height: 12 }}></Row>
                        <Link to="/signin">
                            <Button variant="success">
                                <strong>Get started</strong>
                            </Button>
                        </Link>
                        <Row style={{ height: 12 }}></Row>
                        <a href="https://sites.google.com/view/givingthedistance">
                            <Button variant="outline-primary">
                                <strong>Learn more</strong>
                            </Button>
                        </a>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default Landing;
