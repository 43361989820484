import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { forgotPassword } from "../../../utils/cognitoAuth";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import RedirectNotificationComponent from "../../../components/RedirectNotificationComponent";

import "./ForgotPassword.css";

function ForgotPassword() {
    const navigate = useNavigate();

    const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
    const [forgotPasswordError, setForgotPasswordError] = useState("");

    const handleSubmit = async (event) => {
        console.log(forgotPasswordEmail);
        event.preventDefault();
        event.stopPropagation();

        const forgotPasswordResult = await forgotPassword(forgotPasswordEmail)
            .then((result) => {
                console.log(result);
                setForgotPasswordError("");
                navigate("/forgotpasswordverify?email=" + forgotPasswordEmail);
            })
            .catch((err) => {
                console.log(err);
                if (
                    err?.name === "AuthError" ||
                    err?.name === "NotAuthorizedException" ||
                    err?.name === "UserNotFoundException" ||
                    err?.name === "UserNotConfirmedException"
                ) {
                    setForgotPasswordError(
                        err.message.replace("Username", "Email")
                    );
                }
            });
    };
    return (
        <div className="ForgotPassword">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Giving the Distance | Forgot Password</title>
            </Helmet>
            <Container>
                <Row style={{ height: 12 }}></Row>
                <h3>Forgot your password?</h3>
                <RedirectNotificationComponent />
                <Row style={{ height: 12 }}></Row>
                <Row>
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationCustomEmail"
                            >
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="address@example.com"
                                    defaultValue=""
                                    onChange={(event) => {
                                        setForgotPasswordEmail(
                                            event.target.value
                                        );
                                    }}
                                />
                            </Form.Group>
                        </Row>
                        <Button type="submit">Send reset password link</Button>
                        <Row style={{ height: 12 }}></Row>
                        <Row className="mb-3">
                            <Link to="/signin">
                                Remembered your password? Sign in here!
                            </Link>
                        </Row>

                        {forgotPasswordError ? (
                            <>
                                <Row style={{ height: 12 }}></Row>
                                <Row className="mb-3">
                                    <Alert variant={"danger"}>
                                        {forgotPasswordError}
                                    </Alert>
                                </Row>
                            </>
                        ) : null}
                    </Form>
                </Row>
            </Container>
        </div>
    );
}

export default ForgotPassword;
