import axios from "axios";
import { getIdToken } from "./cognitoAuth";

export async function axiosWithAuth(method, path, body) {
    try {
        const idToken = await getIdToken();
        return await axios
            .create({
                baseURL: "https://beta.api.givingthedistance.org/",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${idToken}`,
                },
            })
            [method](path, body);
    } catch (err) {
        throw err;
    }
}
