import { AxiosError } from "axios";
import { axiosWithAuth } from "../axiosWithAuth";

export async function getStripeSetupIntentClientSecret() {
    return axiosWithAuth("get", "/stripe/secret")
        .then(({ data }) => {
            console.log(data);
            return data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function addSponsorStripePaymentMethodID(stripePaymentMethodID) {
    return axiosWithAuth("post", "/paymentmethod", {
        stripePaymentMethodID,
    })
        .then(({ data }) => {
            console.log(data);
            return data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}
