import { AxiosError } from "axios";
import { axiosWithAuth } from "../axiosWithAuth";
import { getCurrentUser } from "../cognitoAuth";

export async function getUserOverview(userID) {
    var currentUser = await getCurrentUser();
    if (currentUser === null) {
        return null;
    }
    if (currentUser instanceof Error) {
        return currentUser;
    }

    return axiosWithAuth("get", `/user/${userID}/overview`)
        .then(({ data }) => {
            console.log(data);
            return data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}
