import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { cancelSponsorship } from "../utils/db/Sponsorship";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export default function CancelSponsorshipModal(props) {
    const navigate = useNavigate();

    const handleCancelSponsorship = async () => {
        cancelSponsorship(props.userID)
            .then((result) => {
                if (result instanceof Error) {
                    console.error(result);
                    console.log("result.response.data", result.response.data);
                    if (result.name === "AxiosError") {
                        if (result.response.status === 400) {
                            if (
                                result.response.data.code ===
                                "sponsorship_rate_already_cancelled"
                            ) {
                                navigate("/profile/" + props.userID);
                                return;
                            }
                        }
                    }
                    return;
                }
                navigate("/profile/" + props.userID);
                if (props.callback) {
                    props.callback();
                }
            })
            .catch((err) => {
                console.error(err);
                navigate("/profile/" + props.userID);
            });
        props.setShowCancelModal(false);
    };

    return (
        <Modal
            show={props.showCancelModal}
            onHide={() => {
                props.setShowCancelModal(false);
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Just checking...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Do you want to cancel your Sponsorship for this athlete?
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        props.setShowCancelModal(false);
                    }}
                >
                    Close
                </Button>
                <Button variant="primary" onClick={handleCancelSponsorship}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
