import React, { useState } from "react";
import {
    Route,
    Navigate,
    useNavigate,
    useLocation,
    useSearchParams,
} from "react-router-dom";
import useAuth from "../components/AuthContext";
import LoadingComponent from "./LoadingComponent";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const PrivateRoute = ({ children }) => {
    const locationPath = useLocation().pathname;
    const [searchParams, setSearchParams] = useSearchParams();
    console.log(searchParams.toString());

    const { loadingCurrentUser, currentUser } = useAuth();
    console.log("PrivateRoute: ", currentUser);
    if (loadingCurrentUser) {
        return <LoadingComponent />;
    }

    if (!currentUser) {
        var urlEncodedLocationPath = encodeURIComponent(
            locationPath + "?" + searchParams.toString()
        );
        localStorage.setItem("redirectAfterLogin", urlEncodedLocationPath);
        localStorage.setItem("showRedirectNotification", true);
    }
    return currentUser ? children : <Navigate to="/signin" />;
};
// TODO: Create a similar route to prevent the user from going to login screen while already logged in...
// Maybe... AuthenticationRoute? (routes to Home)
//      - SignIn
//      - SignUp
//      - VerifySecurityCode
