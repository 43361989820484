import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import { InputGroup } from "react-bootstrap";

import Cards from "react-credit-cards";

import "./SetupSponsorship.css";
import "react-credit-cards/es/styles-compiled.css";

import {
    createSponsorship,
    getSponsorship,
    getSponsorshipRate,
    cancelSponsorship,
    getSetupSponsorshipOverview,
} from "../../utils/db/Sponsorship";
import CancelSponsorshipModal from "../../components/CancelSponsorshipModal";

function SetupSponsorship() {
    const params = useParams();
    const navigate = useNavigate();

    const [existingSponsorship, setExistingSponsorship] = useState(undefined);
    const [existingSponsorshipError, setExistingSponsorshipError] =
        useState("");

    const [showSavedPaymentMethod, setShowSavedPaymentMethod] = useState(false);

    // TODO: Use something like this
    const [existingSponsorshipOverview, setExistingSponsorshipOverview] =
        useState(undefined);

    const [ccDetails, setCcDetails] = useState(undefined);
    const [paymentMethodExists, setPaymentMethodExists] = useState(undefined);

    const [generalFeedback, setGeneralFeedback] = useState("");

    const [allActivitiesRateEnabled, setAllActivitiesRateEnabled] =
        useState(true);
    const [allActivitiesRateCents, setAllActivitiesRateCents] = useState(10);
    const [allActivitiesRateCentsFeedback, setAllActivitiesRateCentsFeedback] =
        useState("");

    const [runRateCents, setRunRateCents] = useState(10);
    const [runRateCentsFeedback, setRunRateCentsFeedback] = useState("");

    const [bikeRateCents, setBikeRateCents] = useState(10);
    const [bikeRateCentsFeedback, setBikeRateCentsFeedback] = useState("");

    const [swimRateCents, setSwimRateCents] = useState(10);
    const [swimRateCentsFeedback, setSwimRateCentsFeedback] = useState("");

    const [hikeRateCents, setHikeRateCents] = useState(10);
    const [hikeRateCentsFeedback, setHikeRateCentsFeedback] = useState("");

    const [expiresOnEnabled, setExpiresOnEnabled] = useState(false);
    const [expiresOn, setExpiresOn] = useState(0);

    const [maximumCentsEnabled, setMaximumCentsEnabled] = useState(false);
    const [maximumCents, setMaximumCents] = useState(0);

    const [showCancelModal, setShowCancelModal] = useState(false);

    const sponsoredAthleteUserID = params.userId;
    useEffect(() => {
        getSetupSponsorshipOverview(sponsoredAthleteUserID)
            .then((result) => {
                // console.log(result);
                if (result instanceof Error) {
                    if (result.name === "AxiosError") {
                        if (result?.response?.status === 400) {
                            if (
                                result.response.data.code ===
                                "not_both_users_exist"
                            ) {
                                setExistingSponsorshipError(
                                    "The User ID provided in the URL does not match a valid User."
                                );
                                return;
                            }
                            return;
                        }
                        if (result?.response?.status === 404) {
                            setExistingSponsorship(false);
                            return;
                        }
                        if (result?.response?.status === 500) {
                            setExistingSponsorshipError(
                                "Unable to get the current data on any existing Sponsorships between users."
                            );
                            return;
                        }
                    }
                }

                // Successfully retrieved the existing Sponsorship
                if (result.paymentMethod.type === "none") {
                    setPaymentMethodExists(false);
                } else {
                    result.paymentMethod.card.firstName =
                        result.sponsor.firstName;
                    result.paymentMethod.card.lastName =
                        result.sponsor.lastName;
                    setCcDetails(result.paymentMethod.card);
                    setPaymentMethodExists(true);
                }
                setExistingSponsorshipOverview(result);

                var sponsorshipRate = result.rate;
                if (sponsorshipRate.createdOn === 0) {
                    // 0 is the default value of a SponsorshipRate that does not exist
                    setExistingSponsorship(false);
                    return;
                }
                if (sponsorshipRate.deactivationReason !== "NONE") {
                    console.log(
                        "DEACTIVATION_REASON",
                        sponsorshipRate.deactivationReason
                    );
                    setExistingSponsorship(false);
                    return;
                }
                if (
                    sponsorshipRate.expiresOn !== 0 && // 0 value means never expiress
                    sponsorshipRate.expiresOn < new Date().getTime()
                ) {
                    console.log("EXPIRES_ON", sponsorshipRate.expiresOn);
                    setExistingSponsorship(false);
                    return;
                }

                setExistingSponsorship(true);
            })
            .catch((err) => {
                console.error(err);
                setExistingSponsorshipError(
                    "Failed to check for an existing Sponsorship."
                );
            });
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        console.log("allActivitiesRateEnabled", allActivitiesRateEnabled);
        console.log("allActivitiesRateCents", allActivitiesRateCents);

        console.log(
            runRateCents < 1,
            runRateCents > 1000,
            !Number.isInteger(Number(runRateCents))
        );
        console.log(
            bikeRateCents < 1,
            bikeRateCents > 1000,
            !Number.isInteger(Number(bikeRateCents))
        );
        console.log(
            swimRateCents < 1,
            swimRateCents > 1000,
            !Number.isInteger(Number(swimRateCents))
        );
        console.log(
            hikeRateCents < 1,
            hikeRateCents > 1000,
            !Number.isInteger(Number(hikeRateCents))
        );

        console.log(
            "expiresOn",
            expiresOn,
            new Date(expiresOn).toISOString(),
            new Date(expiresOn).getTime()
        );

        // Validate form data
        if (allActivitiesRateEnabled) {
            if (
                allActivitiesRateCents < 1 ||
                allActivitiesRateCents > 1000 ||
                !Number.isInteger(Number(allActivitiesRateCents))
            ) {
                setGeneralFeedback(
                    'Please enter a valid "default" sponsorship amount between $0.01 and $10.00'
                );
                return;
            } else {
                setGeneralFeedback("");
            }
        } else {
            console.log("runRateCents", runRateCents);
            if (
                runRateCents < 1 ||
                runRateCents > 1000 ||
                !Number.isInteger(Number(runRateCents))
            ) {
                setGeneralFeedback(
                    'Please enter a valid "run" sponsorship amount between $0.01 and $10.00'
                );
                return;
            } else {
                setGeneralFeedback("");
            }
            console.log("bikeRateCents", bikeRateCents);
            if (
                bikeRateCents < 1 ||
                bikeRateCents > 1000 ||
                !Number.isInteger(Number(bikeRateCents))
            ) {
                setGeneralFeedback(
                    'Please enter a valid "bike" sponsorship amount between $0.01 and $10.00'
                );
                return;
            } else {
                setGeneralFeedback("");
            }
            console.log("swimRateCents", swimRateCents);
            if (
                swimRateCents < 1 ||
                swimRateCents > 1000 ||
                !Number.isInteger(Number(swimRateCents))
            ) {
                setGeneralFeedback(
                    'Please enter a valid "swim" sponsorship amount between $0.01 and $10.00'
                );
                return;
            } else {
                setGeneralFeedback("");
            }
            console.log("hikeRateCents", hikeRateCents);
            if (
                hikeRateCents < 1 ||
                hikeRateCents > 1000 ||
                !Number.isInteger(Number(hikeRateCents))
            ) {
                setGeneralFeedback(
                    'Please enter a valid "hike" sponsorship amount between $0.01 and $10.00'
                );
                return;
            } else {
                setGeneralFeedback("");
            }
        }

        if (expiresOnEnabled) {
            if (expiresOn === 0) {
                setGeneralFeedback(
                    "Please enter a valid expiration date for the sponsorship."
                );
                return;
            } else if (expiresOn < new Date().getTime()) {
                setGeneralFeedback(
                    "Please enter an expiration date that is in the future."
                );
                return;
            } else {
                setGeneralFeedback("");
            }
        }

        if (maximumCentsEnabled) {
            if (maximumCents < 100 || maximumCents > 1000000) {
                setGeneralFeedback(
                    'Please enter a valid "maximum" sponsorship amount between $1.00 and $10,000.00'
                );
                return;
            } else {
                setGeneralFeedback("");
            }
        }

        // Submit form data
        const rate = {
            allActivitiesRateEnabled,
            allActivitiesRateCents: Number(allActivitiesRateCents),
            runRateCents: Number(runRateCents),
            bikeRateCents: Number(bikeRateCents),
            swimRateCents: Number(swimRateCents),
            hikeRateCents: Number(hikeRateCents),
            expiresOn: Number(expiresOn),
            maximumCents: Number(maximumCents),
        };
        var createSponsorshipResponse = await createSponsorship(
            sponsoredAthleteUserID,
            rate
        );
        if (createSponsorshipResponse instanceof Error) {
            console.error(
                "createSponsorshipResponse",
                createSponsorshipResponse
            );
            setGeneralFeedback(
                "Failed to create a Sponsorship for the athlete."
            );
        } else {
            console.log("createSponsorshipResponse", createSponsorshipResponse);
            console.log("Successfully created a Sponsorship for the athlete.");
        }

        console.log("generalFeedback", generalFeedback);
        navigate("/profile/" + sponsoredAthleteUserID);
    };

    let formatting_options = {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
    };
    let dollarString = new Intl.NumberFormat("en-US", formatting_options);

    var renderedComponent = undefined;

    const loadingComponent = (
        <Row>
            <Alert variant="light" show={existingSponsorship === undefined}>
                Loading...
            </Alert>
        </Row>
    );

    if (existingSponsorship === undefined) {
        renderedComponent = loadingComponent;
    }

    const existingSponsorshipComponent = (
        <Row>
            <CancelSponsorshipModal
                userID={sponsoredAthleteUserID}
                showCancelModal={showCancelModal}
                setShowCancelModal={setShowCancelModal}
            />
            <Col>
                <Alert variant="primary" show={existingSponsorship === true}>
                    You are already sponsoring this athlete.
                </Alert>
                <Row style={{ height: 12 }}></Row>
                <Link to={`/profile/${sponsoredAthleteUserID}`}>
                    <Button variant="outline-secondary">Back</Button>
                </Link>{" "}
                <Button
                    variant="outline-danger"
                    onClick={() => {
                        setShowCancelModal(true);
                    }}
                >
                    Cancel sponsorship
                </Button>
            </Col>
        </Row>
    );

    if (existingSponsorship === true) {
        renderedComponent = existingSponsorshipComponent;
    }

    const existingSponsorshipErrorComponent = (
        <Row>
            <Alert variant="danger" show={existingSponsorshipError !== ""}>
                {existingSponsorshipError}
            </Alert>
        </Row>
    );

    if (existingSponsorshipError !== "") {
        renderedComponent = existingSponsorshipErrorComponent;
    }

    const noValidPaymentMethodComponent = (
        <Row>
            <Col>
                <Alert variant="danger" show={paymentMethodExists !== true}>
                    You have not set up a valid payment method yet, or any
                    payment method you have set up has expired. Please set up a
                    valid payment method before sponsoring an athlete.
                </Alert>
                <Link to={`/profile/${sponsoredAthleteUserID}`}>
                    <Button variant="outline-secondary">Back</Button>
                </Link>{" "}
                <Link to="/payment/setup">
                    <Button variant="outline-primary">
                        Setup payment method
                    </Button>
                </Link>
            </Col>
        </Row>
    );

    if (paymentMethodExists !== true && paymentMethodExists !== undefined) {
        renderedComponent = noValidPaymentMethodComponent;
    }

    const normalSponsorshipFormComponent = (
        <Row>
            {existingSponsorshipOverview ? (
                <Row>
                    <Col>
                        <h4>
                            {`Sponsored Athlete: ${existingSponsorshipOverview.athlete.firstName} ${existingSponsorshipOverview.athlete.lastName}`}
                        </h4>
                    </Col>
                </Row>
            ) : undefined}
            <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group controlId="validationCustomAllActivitiesRateEnabled">
                        <Form.Check
                            type="checkbox"
                            label="Use the same (default) sponsorship rate for all
                                    activity types"
                            onChange={(event) => {
                                setAllActivitiesRateEnabled(
                                    event.target.checked
                                );
                            }}
                            defaultChecked="true"
                        />
                    </Form.Group>
                </Row>
                {allActivitiesRateEnabled ? (
                    <Row className="mb-3">
                        <Form.Group controlId="validationCustomAllActivitiesRateCents">
                            <Form.Label>
                                <strong>Default</strong> - Sponsorship rate per
                                mile (<strong>cents</strong>, USD) (all activity
                                types will be sponsored at the same rate)
                            </Form.Label>
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">
                                    {dollarString.format(
                                        allActivitiesRateCents / 100
                                    )}
                                </InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    placeholder=""
                                    defaultValue="10"
                                    onChange={(event) => {
                                        setAllActivitiesRateCents(
                                            event.target.value
                                        );
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {allActivitiesRateCentsFeedback}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                ) : (
                    <>
                        <Row className="mb-3">
                            <Form.Group controlId="validationCustomRunRateCents">
                                <Form.Label>
                                    <strong>Running</strong> - Sponsorship rate
                                    per mile (<strong>cents</strong>, USD)
                                </Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        {dollarString.format(
                                            runRateCents / 100
                                        )}
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        placeholder=""
                                        defaultValue="10"
                                        onChange={(event) => {
                                            setRunRateCents(event.target.value);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {runRateCentsFeedback}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group controlId="validationCustomBikeRateCents">
                                <Form.Label>
                                    <strong>Biking</strong> - Sponsorship rate
                                    per mile (<strong>cents</strong>, USD)
                                </Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        {dollarString.format(
                                            bikeRateCents / 100
                                        )}
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        placeholder=""
                                        defaultValue="10"
                                        onChange={(event) => {
                                            setBikeRateCents(
                                                event.target.value
                                            );
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {bikeRateCentsFeedback}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group controlId="validationCustomSwimRateCents">
                                <Form.Label>
                                    <strong>Swimming</strong> - Sponsorship rate
                                    per mile (<strong>cents</strong>, USD)
                                </Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        {dollarString.format(
                                            swimRateCents / 100
                                        )}
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        placeholder=""
                                        defaultValue="10"
                                        onChange={(event) => {
                                            setSwimRateCents(
                                                event.target.value
                                            );
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {swimRateCentsFeedback}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group controlId="validationCustomHikeRateCents">
                                <Form.Label>
                                    <strong>Hiking</strong> - Sponsorship rate
                                    per mile (<strong>cents</strong>, USD)
                                </Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        {dollarString.format(
                                            hikeRateCents / 100
                                        )}
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        placeholder=""
                                        defaultValue="10"
                                        onChange={(event) => {
                                            setHikeRateCents(
                                                event.target.value
                                            );
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {hikeRateCentsFeedback}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                    </>
                )}
                <Row className="mb-3">
                    <Form.Group controlId="validationCustomExpiresOnEnabled">
                        <Form.Check
                            type="checkbox"
                            label="Should the sponsorship expire?"
                            onChange={(event) => {
                                setExpiresOnEnabled(event.target.checked);
                            }}
                            defaultChecked={false}
                        />
                    </Form.Group>
                </Row>

                {expiresOnEnabled ? (
                    <Row className="mb-3">
                        <Form.Group controlId="validationCustomExpiresOn">
                            <Form.Label>
                                When should the sponsorship expire?
                            </Form.Label>
                            <Form.Control
                                type="date"
                                name="expiration_date"
                                onChange={(event) => {
                                    var offsetMillis =
                                        new Date().getTimezoneOffset() *
                                        60 *
                                        1000;
                                    var date =
                                        new Date(event.target.value).getTime() +
                                        offsetMillis;
                                    setExpiresOn(date);
                                }}
                            />
                        </Form.Group>
                    </Row>
                ) : null}

                <Row className="mb-3">
                    <Form.Group controlId="validationCustomMaximumCentsEnabled">
                        <Form.Check
                            type="checkbox"
                            label="Do you want to cap your sponsorship at a maximum donation total?"
                            onChange={(event) => {
                                setMaximumCentsEnabled(event.target.checked);
                            }}
                            defaultChecked={false}
                        />
                    </Form.Group>
                </Row>
                {maximumCentsEnabled ? (
                    <Row className="mb-3">
                        <Form.Group controlId="validationCustomMaximumCents">
                            <Form.Label>
                                Total sponsorship amount (<strong>cents</strong>
                                , USD)
                            </Form.Label>
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">
                                    {dollarString.format(maximumCents / 100)}
                                </InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    placeholder=""
                                    defaultValue="0"
                                    onChange={(event) => {
                                        setMaximumCents(event.target.value);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                ) : null}

                <Row className="mb-3">
                    <Col>
                        <Button
                            variant="outline-primary"
                            onClick={() => {
                                setShowSavedPaymentMethod(
                                    !showSavedPaymentMethod
                                );
                            }}
                        >
                            {showSavedPaymentMethod ? "Hide" : "Show"} saved
                            payment method details
                        </Button>
                    </Col>
                    {showSavedPaymentMethod ? (
                        <Col lg={12} md={12} sm={12}>
                            <Row style={{ height: 12 }}></Row>
                            <h4>Payment Method</h4>
                            {ccDetails ? (
                                <Cards
                                    expiry={
                                        ccDetails.expMonth +
                                        "/" +
                                        ccDetails.expYear
                                    }
                                    number={"************" + ccDetails.last4}
                                    cvc={""}
                                    issuer={ccDetails.brand}
                                    name={
                                        ccDetails.firstName +
                                        " " +
                                        ccDetails.lastName
                                    }
                                    preview={true}
                                />
                            ) : undefined}
                        </Col>
                    ) : undefined}
                </Row>

                <Button variant="primary" type="submit">
                    Create
                </Button>
                <Row style={{ height: 12 }}></Row>
                <Link to={`/profile/${sponsoredAthleteUserID}`}>
                    <Button variant="outline-secondary" type="submit">
                        Back
                    </Button>
                </Link>
            </Form>
        </Row>
    );

    if (renderedComponent === undefined) {
        renderedComponent = normalSponsorshipFormComponent;
    }

    return (
        <div className="SetupSponsorship">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Giving the Distance | Setup Sponsorship`}</title>
            </Helmet>
            <Container>
                <Row style={{ height: 12 }}></Row>
                <h3>Setup Sponsorship</h3>
                <Row style={{ height: 12 }}></Row> {renderedComponent}
                <Row style={{ height: 12 }}></Row>
                <Alert variant="danger" show={generalFeedback.length > 0}>
                    {generalFeedback}
                </Alert>
            </Container>
        </div>
    );
}

export default SetupSponsorship;
