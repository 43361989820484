import React from "react";
import { Navigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import useAuth from "../../components/AuthContext";
import LoadingComponent from "../../components/LoadingComponent";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export default function Home() {
    const { loadingCurrentUser, currentUser } = useAuth(); // isauth() returns true or false based on localStorage
    console.log("Home: ", currentUser);

    const loadingComponent = (
        <div className="Home">
            <Container>
                <LoadingComponent />
            </Container>
        </div>
    );

    if (loadingCurrentUser) {
        return { loadingComponent };
    }

    return currentUser ? (
        <Navigate to={`/profile/${currentUser.sub}`} />
    ) : (
        { loadingComponent }
    );
}
