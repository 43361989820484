import React, { useState } from "react";
import {
    Route,
    Navigate,
    useNavigate,
    useLocation,
    useSearchParams,
} from "react-router-dom";
import useAuth from "./AuthContext";
import LoadingComponent from "./LoadingComponent";

// A wrapper for <Route> that redirects to the home
// screen if you're already authenticated.
export const AuthenticatedRoute = ({ children }) => {
    const { loadingCurrentUser, currentUser } = useAuth();
    console.log("AuthenticatedRoute: ", currentUser);
    if (loadingCurrentUser) {
        return <LoadingComponent />;
    }

    return currentUser ? <Navigate to="/home" /> : children;
};
