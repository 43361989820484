import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useStripe } from "@stripe/react-stripe-js";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import { addSponsorStripePaymentMethodID } from "../../utils/db/Stripe";

import "./StripePaymentMethodEntryConfirmation.css";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
    "pk_test_51Kgd1EBp2tm6vBrPDgsgrHf15ibavqmTHIQQ54yH5D54t12tZG5DxGufNibAeRaPZYxdUKYpbyp8uQAgXV6aqI7400G8XJ3py5"
);

const PaymentStatus = () => {
    const stripe = useStripe();
    const [element, setElement] = useState(null);

    const LinkToADifferentPaymentMethod = (
        <Link to="/payment/setup">
            <Button>Try a different payment method</Button>
        </Link>
    );
    function onAddPaymentMethodFailure() {
        setElement(
            <Row>
                <Col>
                    <Alert variant="warning">
                        Your payment method is valid, but we had a problem
                        saving the details. Please try again, and if the problem
                        persists, contact us.
                    </Alert>
                    <LinkToADifferentPaymentMethod />
                </Col>
            </Row>
        );
    }

    useEffect(() => {
        if (!stripe) {
            return;
        }

        // Retrieve the "setup_intent_client_secret" query parameter appended to
        // your return_url by Stripe.js
        const clientSecret = new URLSearchParams(window.location.search).get(
            "setup_intent_client_secret"
        );
        console.log("clientSecret", clientSecret);

        // Retrieve the SetupIntent
        stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
            console.log("setupIntent", setupIntent);
            // Inspect the SetupIntent `status` to indicate the status of the payment
            // to your customer.
            //
            // Some payment methods will [immediately succeed or fail][0] upon
            // confirmation, while others will first enter a `processing` state.
            //
            // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
            switch (setupIntent.status) {
                case "succeeded":
                    addSponsorStripePaymentMethodID(setupIntent.payment_method)
                        .then((result) => {
                            console.log(
                                "addSponsorStripePaymentMethodID result",
                                result
                            );
                            if (result.code !== "success") {
                                onAddPaymentMethodFailure();
                                return;
                            }
                            setElement(
                                <Row>
                                    <Col>
                                        <Alert variant="success">
                                            Success! Your payment method has
                                            been saved, and
                                            <strong>
                                                {" "}
                                                now you can sponsor people!
                                            </strong>{" "}
                                            🎉
                                        </Alert>
                                        <Link to="/home">
                                            <Button>Done</Button>
                                        </Link>
                                    </Col>
                                </Row>
                            );
                        })
                        .catch((err) => {
                            console.log(
                                "addSponsorStripePaymentMethodID err",
                                err
                            );
                            onAddPaymentMethodFailure();
                        });

                    break;

                case "processing":
                    setElement(
                        <Row>
                            <Col>
                                <Alert variant="info">
                                    Processing payment details. Try refreshing
                                    the page in a minute. If your payment method
                                    still has not been processed by then, it may
                                    take significantly longer. Please contact us
                                    if you have any questions.
                                </Alert>
                            </Col>
                        </Row>
                    );
                    break;

                case "requires_payment_method":
                    // Redirect your user back to your payment page to attempt collecting
                    // payment again
                    setElement(
                        <Row>
                            <Col>
                                <Alert variant="info">
                                    Failed to process payment details. Please
                                    try another payment method.
                                </Alert>
                                <LinkToADifferentPaymentMethod />
                            </Col>
                        </Row>
                    );
                    break;
                default:
                    setElement(
                        <Row>
                            <Col>
                                <Alert variant="warning">
                                    While processing payment method, our backend
                                    received an unexpected status:{" "}
                                    {setupIntent.status}. Please contact us if
                                    you have any questions.
                                </Alert>
                                <LinkToADifferentPaymentMethod />
                            </Col>
                        </Row>
                    );
            }
        });
    }, [stripe]);

    return element ? (
        element
    ) : (
        <Row>
            <Col>
                <Alert variant="light">Loading...</Alert>
            </Col>
        </Row>
    );
};

function StripePaymentMethodEntryConfirmation() {
    return (
        <div className="StripePaymentMethodEntryConfirmation">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Giving the Distance | Payment Method Confirmation</title>
            </Helmet>
            <Container>
                <Row style={{ height: 12 }}></Row>
                <h3>Stripe Payment Method Entry Confirmation</h3>
                <Row style={{ height: 12 }}></Row>

                <Elements stripe={stripePromise}>
                    {/* PaymentStatus needs to be wrapped in an 'Elements' element */}
                    <PaymentStatus />
                </Elements>
            </Container>
        </div>
    );
}

export default StripePaymentMethodEntryConfirmation;
