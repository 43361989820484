import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { getUserOverview } from "../../utils/db/User";

import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import CancelSponsorshipModal from "../../components/CancelSponsorshipModal";

import GenericAvatar from "../../media/generic-avatar.jpeg";

import "./Profile.css";
import { Spinner, Toast } from "react-bootstrap";
import LoadingComponent from "../../components/LoadingComponent";
import { AxiosError } from "axios";

// TODO: Cover case for 0 entries in each table
const AthletePanel = (userOverview) => {
    const notYetAuthorizedWithStravaCard = (
        <Card>
            <Card.Body>
                You have not yet connected Strava<sup>TM</sup>.
                <hr />
                <Link to="/strava/connect">
                    <Button variant="outline-primary">
                        Integrate with Strava
                        <sup>TM</sup> 🏃‍♀️
                    </Button>
                </Link>
            </Card.Body>
        </Card>
    );

    const activityTable = userOverview.athlete.stravaAuthorized ? (
        userOverview.athlete.activities.items.length > 0 ? (
            <Row className="custom-table-container">
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Activity</th>
                            <th>Miles</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userOverview.athlete.activities.items.map(
                            (activity) => {
                                const dateString = new Date(
                                    activity.date
                                ).toLocaleDateString();
                                const milesHundredths = Math.round(
                                    activity.meters * 0.000621371 * 100
                                );
                                return (
                                    <tr key={`activity-${activity.id}`}>
                                        <td>{dateString}</td>
                                        <td>{activity.type}</td>
                                        <td>{milesHundredths / 100}</td>
                                    </tr>
                                );
                            }
                        )}
                    </tbody>
                </Table>
            </Row>
        ) : (
            <Alert variant="warning">
                <strong>No activities found.</strong>
                <hr />
                Connect your Strava account above to start automatically
                recording your activities.
            </Alert>
        )
    ) : undefined;

    const sponsorTable = userOverview.athlete.stravaAuthorized ? (
        userOverview.athlete.incomingSponsorships.items.length > 0 ? (
            <Row className="custom-table-container">
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userOverview.athlete.incomingSponsorships.items.map(
                            (item) => {
                                return (
                                    <tr key={`incoming-sponsorship-${item.id}`}>
                                        <td>
                                            <Link
                                                to={`/profile/${item.sponsor.id}`}
                                            >
                                                {`${item.sponsor.firstName} ${item.sponsor.lastName}`}
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            }
                        )}
                    </tbody>
                </Table>
            </Row>
        ) : (
            <Alert variant="warning">
                <strong>No sponsors found.</strong>
                <hr />
                Share the link to your profile to invite friends to sponsor your
                activities!
            </Alert>
        )
    ) : undefined;

    return (
        <Col
            className="ProfileAthletePanel"
            lg={6}
            sm={12}
            style={{ marginBottom: 12 }}
        >
            <Card>
                <Card.Header>Athlete Overview</Card.Header>
                <Card.Body>
                    <Container>
                        {userOverview.athlete.stravaAuthorized ? (
                            <Row>
                                <Col>
                                    <h4>Activities</h4>
                                    <hr />
                                    {activityTable}
                                    <Row style={{ height: 12 }}></Row>
                                    <h4>Sponsors</h4>
                                    <hr />
                                    {sponsorTable}
                                </Col>
                            </Row>
                        ) : (
                            <Row>{notYetAuthorizedWithStravaCard}</Row>
                        )}
                    </Container>
                </Card.Body>
            </Card>
        </Col>
    );
};

const SponsorPanel = (userOverview) => {
    const donationTable =
        userOverview.sponsor.paymentMethod.type !== "none" ? (
            userOverview.sponsor.donations.items.length > 0 ? (
                <Row className="custom-table-container">
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userOverview.sponsor.donations.items.map(
                                (donation) => {
                                    const dateString = new Date(
                                        donation.createdOn
                                    ).toLocaleDateString();
                                    const dollarString = (
                                        donation.amountInCents / 100
                                    ).toFixed(2);
                                    return (
                                        <tr key={`donation-${donation.id}`}>
                                            <td>{dateString}</td>
                                            <td>${dollarString}</td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </Table>
                </Row>
            ) : (
                <Alert variant="warning">
                    <strong>No donations found.</strong>
                    <hr />
                    Once you sponsor an athlete, their activities will
                    automatically generate "commitments." Once you generate
                    enough commitments, your donation will automatically be
                    processed!
                </Alert>
            )
        ) : undefined;

    const commitmentTable =
        userOverview.sponsor.paymentMethod.type !== "none" ? (
            userOverview.sponsor.commitments.items.length > 0 ? (
                <Row className="custom-table-container">
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Athlete</th>
                                <th>Amount</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userOverview.sponsor.commitments.items.map(
                                (commitment) => {
                                    const dateString = new Date(
                                        commitment.createdOn
                                    ).toLocaleDateString();
                                    const dollarString = (
                                        commitment.amountInCents / 100
                                    ).toFixed(2);
                                    return (
                                        <tr key={`commitment-${commitment.id}`}>
                                            <td>{dateString}</td>
                                            <td>{`${commitment.athlete.firstName} ${commitment.athlete.lastName}`}</td>
                                            <td>${dollarString}</td>
                                            <td>
                                                {commitment.donationID
                                                    ? "Complete"
                                                    : "Pending"}
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </Table>
                </Row>
            ) : (
                <Alert variant="warning">
                    <strong>No commitments found.</strong>
                    <hr />
                    Once you sponsor an athlete, their activities will
                    automatically generate "commitments." Once you generate
                    enough commitments, your donation will automatically be
                    processed!
                </Alert>
            )
        ) : undefined;

    const outgoingSponsorshipsTable =
        userOverview.sponsor.paymentMethod.type !== "none" ? (
            userOverview.sponsor.outgoingSponsorships.items.length > 0 ? (
                <Row className="custom-table-container">
                    {/* <Card>
                        <Card.Body>
                            <strong>Athlete</strong>
                            <hr />
                            <Row className="custom-table-container">
                                {userOverview.sponsor.outgoingSponsorships.items.map(
                                    (sponsorship, index) => (
                                        <>
                                            <Row
                                                key={`sponsorship-${sponsorship.id}`}
                                                style={{ marginBottom: "12px" }}
                                            >
                                                <Link
                                                    to={`/profile/${sponsorship.athlete.id}`}
                                                >
                                                    {`${sponsorship.athlete.firstName} ${sponsorship.athlete.lastName}`}
                                                </Link>
                                            </Row>
                                            {index !==
                                            userOverview.sponsor
                                                .outgoingSponsorships.items
                                                .length -
                                                1 ? (
                                                <hr />
                                            ) : undefined}
                                        </>
                                    )
                                )}
                            </Row>
                        </Card.Body>
                    </Card> */}
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>Athlete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userOverview.sponsor.outgoingSponsorships.items.map(
                                (sponsorship) => (
                                    <tr key={`sponsorship-${sponsorship.id}`}>
                                        <td>
                                            <Link
                                                to={`/profile/${sponsorship.athlete.id}`}
                                            >
                                                {`${sponsorship.athlete.firstName} ${sponsorship.athlete.lastName}`}
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </Table>
                </Row>
            ) : (
                <Alert variant="warning">
                    <strong>No outgoing sponsorships found.</strong>
                    <hr />
                    Once you sponsor an athlete, you'll see it here!
                </Alert>
            )
        ) : undefined;

    return (
        <Col className="SponsorAthletePanel" lg={6} sm={12}>
            <Card>
                <Card.Header>Sponsor Overview</Card.Header>
                <Card.Body>
                    <Container>
                        {userOverview.sponsor.paymentMethod.type === "none" ? (
                            <Row>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            You have not entered a valid payment
                                            method yet.
                                            <hr />
                                            <Link to="/payment/setup">
                                                <Button variant="outline-primary">
                                                    Setup sponsorship payment
                                                    method
                                                </Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col>
                                    <h4>Donations</h4>
                                    <hr />
                                    {donationTable}
                                    <Row style={{ height: 12 }}></Row>
                                    <h4>Commitments</h4>
                                    <hr />
                                    {commitmentTable}
                                    <Row style={{ height: 12 }}></Row>
                                    <h4>Outgoing Sponsorships</h4>
                                    <hr />
                                    {outgoingSponsorshipsTable}
                                </Col>
                            </Row>
                        )}
                    </Container>
                </Card.Body>
            </Card>
            <Row style={{ height: 12 }}></Row>
        </Col>
    );
};

function Profile() {
    const params = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [userOverview, setUserOverview] = useState(undefined);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showCopiedAthleteToast, setShowCopiedAthleteToast] = useState(false);

    async function fetchUserOverview() {
        setLoading(true);
        return await getUserOverview(params.userId).then((userOverview) => {
            if (userOverview instanceof Error) {
                if (userOverview instanceof AxiosError) {
                    if (userOverview.response?.status === 404) {
                        console.log(
                            "Client tried to view a user which does not exist. Redirecting to home."
                        );
                        navigate("/home");
                    }
                }
            }
            setUserOverview(userOverview);
            setLoading(false);
            console.log(userOverview);
        });
    }

    function onClickShare() {
        navigator.clipboard.writeText(
            `${window.location.hostname}/profile/${params.userId}`
        );
        setShowCopiedAthleteToast(true);
    }

    useEffect(() => {
        fetchUserOverview();
    }, [params.userId]);

    if (loading) {
        return (
            <div className="Profile">
                <Container>
                    <LoadingComponent />
                </Container>
            </div>
        );
    }

    return (
        <div className="Profile">
            <Container>
                {userOverview ? (
                    <Col>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>{`${userOverview.firstName} ${userOverview.lastName}'s Profile`}</title>
                        </Helmet>
                        <CancelSponsorshipModal
                            userID={params.userId}
                            showCancelModal={showCancelModal}
                            setShowCancelModal={setShowCancelModal}
                            callback={fetchUserOverview}
                        />
                        <Row style={{ height: 12 }}></Row>
                        <Row>
                            <img
                                id="profile-picture"
                                src={
                                    userOverview.profilePicture
                                        ? userOverview.profilePicture
                                        : GenericAvatar
                                }
                                alt="profile pic of person"
                            />
                            <h1 className="display-4" id="profile-name">
                                {`${userOverview.firstName} ${userOverview.lastName}`}
                            </h1>
                        </Row>
                        <Row style={{ height: 12 }}></Row>
                        <Row>
                            <Col>
                                <Button
                                    variant="outline-primary"
                                    onClick={() => {
                                        onClickShare();
                                    }}
                                >
                                    Share athlete profile 🔗
                                </Button>
                                <Toast
                                    show={showCopiedAthleteToast}
                                    delay={2000}
                                    autohide
                                    onClose={() =>
                                        setShowCopiedAthleteToast(false)
                                    }
                                >
                                    <Toast.Header>
                                        <strong className="me-auto">
                                            Copied URL!
                                        </strong>
                                    </Toast.Header>
                                    <Toast.Body>
                                        Woohoo, you copied this athlete's
                                        profile URL to your clipboard! 📋 Now
                                        paste to your heart's delight! ❤️
                                    </Toast.Body>
                                </Toast>
                            </Col>
                        </Row>
                        <Row style={{ height: 12 }}></Row>
                        <Row>
                            <Col>
                                {userOverview.iSponsorThem ? (
                                    <Button
                                        variant="outline-danger"
                                        onClick={() => {
                                            setShowCancelModal(true);
                                        }}
                                    >
                                        {userOverview.self
                                            ? `Cancel sponsorship of yourself`
                                            : `Cancel sponsorship of ${userOverview.firstName}`}
                                    </Button>
                                ) : (
                                    <Link
                                        to={`/user/${params.userId}/setupsponsorship`}
                                    >
                                        <Button variant="success">
                                            {userOverview.self
                                                ? "Sponsor yourself"
                                                : `Sponsor ${userOverview.firstName}`}
                                        </Button>
                                    </Link>
                                )}
                                <Row style={{ height: 12 }}></Row>
                            </Col>
                        </Row>

                        <Row>
                            <Row>
                                <h5 className="display-10" id="champion-first">
                                    {`${userOverview.firstName} is giving the distance to:`}
                                </h5>
                            </Row>
                            <Row id="charity-row">
                                <Row>
                                    <Col>
                                        <h4
                                            className="display-10"
                                            id="charity-recipient"
                                        >
                                            Against Malaria Foundation |
                                            GiveWell
                                        </h4>

                                        <a href="https://www.givewell.org/charities/amf">
                                            <Button variant="outline-info">
                                                Learn about AMF
                                            </Button>
                                        </a>
                                    </Col>
                                </Row>
                                <Row style={{ height: 12 }}></Row>
                                <Row>
                                    <img
                                        id="charity-image"
                                        src="https://www.givewell.org/sites/default/files/givewell_fb_logo.jpg"
                                        alt="GiveWell logo"
                                    />
                                </Row>
                            </Row>
                        </Row>

                        {userOverview.self ? (
                            <Row>
                                {AthletePanel(userOverview)}
                                {SponsorPanel(userOverview)}
                            </Row>
                        ) : undefined}
                    </Col>
                ) : undefined}
            </Container>
        </div>
    );
}

export default Profile;
