import React, { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { forgotPasswordSubmit } from "../../../utils/cognitoAuth";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import RedirectNotificationComponent from "../../../components/RedirectNotificationComponent";

import "./ForgotPasswordVerify.css";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ForgotPasswordVerify() {
    const navigate = useNavigate();

    const [validated, setValidated] = useState(false);
    let query = useQuery();
    const forgotPasswordVerifyEmail = query.get("email");
    const [forgotPasswordVerifyCode, setForgotPasswordVerifyCode] =
        useState("");
    const [forgotPasswordVerifyError, setForgotPasswordVerifyError] =
        useState("");
    const [forgotPasswordVerifyPassword, setForgotPasswordVerifyPassword] =
        useState("");
    const [forgotPasswordVerifySuccess, setForgotPasswordVerifySuccess] =
        useState(false);

    const handleSubmit = async (event) => {
        console.log(forgotPasswordVerifyEmail, forgotPasswordVerifyCode);
        event.preventDefault();
        event.stopPropagation();

        const verificationResult = await forgotPasswordSubmit(
            forgotPasswordVerifyEmail,
            forgotPasswordVerifyCode,
            forgotPasswordVerifyPassword
        )
            .then((result) => {
                console.log(result);
                setForgotPasswordVerifySuccess(true);
                setForgotPasswordVerifyError("");
            })
            .catch((err) => {
                console.log(err);
                if (
                    err?.name === "AuthError" ||
                    err?.name === "CodeMismatchException"
                ) {
                    setForgotPasswordVerifySuccess(false);
                    setForgotPasswordVerifyError(
                        err.message.replace("Username", "Email")
                    );
                }
            });

        setValidated(true);
    };

    return (
        <div className="ForgotPasswordVerify">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Giving the Distance | Forgot Password Verify</title>
            </Helmet>
            <Container>
                <Row style={{ height: 12 }}></Row>
                <h3>Reset your password</h3>
                <RedirectNotificationComponent />
                <Row style={{ height: 12 }}></Row>
                <Alert variant={"primary"}>
                    An email has been sent to {forgotPasswordVerifyEmail}.
                    Please check for the confirmation code and enter it below
                    along with your new password.
                </Alert>
                <Row>
                    <Form
                        validated={validated}
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <Row className="mb-3">
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationCustomCode"
                            >
                                <Form.Label>Code</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter your code"
                                    defaultValue=""
                                    onChange={(event) => {
                                        setForgotPasswordVerifyCode(
                                            event.target.value
                                        );
                                    }}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationCustomCode"
                            >
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    placeholder="Set a new password"
                                    defaultValue=""
                                    onChange={(event) => {
                                        setForgotPasswordVerifyPassword(
                                            event.target.value
                                        );
                                    }}
                                />
                            </Form.Group>
                        </Row>
                        <Button type="submit">Set new password</Button>
                        <Row style={{ height: 12 }}></Row>
                        <Row className="mb-3">
                            <Link to="/signin">
                                Back to the "Sign in" screen
                            </Link>
                        </Row>
                        {forgotPasswordVerifyError ? (
                            <>
                                <Row style={{ height: 12 }}></Row>
                                <Row className="mb-3">
                                    <Alert variant={"danger"}>
                                        {forgotPasswordVerifyError}
                                    </Alert>
                                </Row>
                            </>
                        ) : null}

                        {forgotPasswordVerifySuccess ? (
                            <>
                                <Row style={{ height: 12 }}></Row>
                                <Row className="mb-3">
                                    <Alert variant={"success"}>
                                        <p>
                                            Your password has successfully been
                                            reset.
                                        </p>
                                        <Link to="/signin">
                                            Back to Sign in page!
                                        </Link>
                                    </Alert>
                                </Row>
                            </>
                        ) : null}
                    </Form>
                </Row>
            </Container>
        </div>
    );
}

export default ForgotPasswordVerify;
