// http://www.strava.com/oauth/authorize?client_id=101361&response_type=code&redirect_uri=http://localhost:3000/strava/exchange_token&approval_prompt=force&scope=read

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useLocation, Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import "./StravaExchangeToken.css";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function StravaExchangeToken() {
    const navigate = useNavigate();

    let query = useQuery();
    const stravaExchangeCustomError = query.get("custom_error");

    var codeSpecificComponent;
    switch (stravaExchangeCustomError) {
        case "access_denied":
            codeSpecificComponent = (
                <Col>
                    <Alert variant="warning">
                        It looks like you denied access to your Strava account.
                        It's cool! You can always connect later 😀
                    </Alert>
                    <Row style={{ height: 12 }}></Row>
                    <Link to="/strava/connect">
                        <Button>Try again</Button>
                    </Link>
                    <Row style={{ height: 12 }}></Row>
                    <Link to="/home">
                        <Button variant="light">Skip for now</Button>
                    </Link>
                </Col>
            );
            break;
        case "invalid_scope":
            codeSpecificComponent = (
                <Col>
                    <Alert variant="warning">
                        Something went wrong while connecting to your Strava
                        account. Please try again later. If the problem
                        persists, please contact us.
                    </Alert>
                    <Row style={{ height: 12 }}></Row>
                    <Link to="/strava/connect">
                        <Button>Try again</Button>
                    </Link>
                    <Row style={{ height: 12 }}></Row>
                    <Link to="/home">
                        <Button variant="light">Skip for now</Button>
                    </Link>
                </Col>
            );
            break;
        case "invalid_code":
            codeSpecificComponent = (
                <Col>
                    <Alert variant="warning">
                        Something went wrong while connecting to your Strava
                        account. Please try again later. If the problem
                        persists, please contact us.
                    </Alert>
                    <Row style={{ height: 12 }}></Row>
                    <Link to="/strava/connect">
                        <Button>Try again</Button>
                    </Link>
                    <Row style={{ height: 12 }}></Row>
                    <Link to="/home">
                        <Button variant="light">Skip for now</Button>
                    </Link>
                </Col>
            );
            break;
        case "insufficient_scope":
            codeSpecificComponent = (
                <Col>
                    <Alert variant="warning">
                        On the last screen, you may not have selected{" "}
                        <strong>all</strong> the necessary permissions. Please
                        try again, and this time, don't uncheck any boxes.
                    </Alert>
                    <Row style={{ height: 12 }}></Row>
                    <Link to="/strava/connect">
                        <Button>Try again</Button>
                    </Link>
                    <Row style={{ height: 12 }}></Row>
                    <Link to="/home">
                        <Button variant="light">Skip for now</Button>
                    </Link>
                </Col>
            );
            break;
        case "user_already_authorized_to_strava_athlete":
            codeSpecificComponent = (
                <Col>
                    <Alert variant="warning">
                        Your profile on Giving the Distance has already been
                        linked to a Strava account. If you would like to link
                        your Giving the Distance profile to a different Strava
                        account, please first sign into your Strava account
                        which you have linked to Giving the Distance and
                        navigate to the following page where you can click
                        "Revoke Access": <br />
                        <Alert.Link href="https://www.strava.com/settings/apps">
                            https://www.strava.com/settings/apps
                        </Alert.Link>
                        .
                        <br />
                        Then, please contact us for further assistance.
                    </Alert>
                    <Row style={{ height: 12 }}></Row>
                    <Link to="/home">
                        <Button variant="light">Skip for now</Button>
                    </Link>
                </Col>
            );
            break;
        case "strava_athlete_already_authorized_to_user":
            codeSpecificComponent = (
                <Col>
                    <Alert variant="warning">
                        You have already linked this Strava account to another
                        Giving the Distance profile! If you would like to link
                        this Strava account to this Giving the Distance profile,
                        please first sign into your Strava account which you
                        have linked to Giving the Distance and navigate to the
                        following page where you can click "Revoke Access":{" "}
                        <br />
                        <Alert.Link href="https://www.strava.com/settings/apps">
                            https://www.strava.com/settings/apps
                        </Alert.Link>
                        .
                        <br />
                        Then, please contact us for further assistance.
                    </Alert>
                    <Row style={{ height: 12 }}></Row>
                    <Link to="/home">
                        <Button variant="light">Skip for now</Button>
                    </Link>
                </Col>
            );
            break;
        case "none":
            codeSpecificComponent = (
                <Col>
                    <Alert variant="success">
                        Your Strava account has been successfully connected! 🎉
                    </Alert>
                    <Row style={{ height: 12 }}></Row>
                    <Link to="/home">
                        <Button>Proceed 🏃‍♀️</Button>
                    </Link>
                </Col>
            );
            break;
        case "internal_error":
        default:
            codeSpecificComponent = (
                <Col>
                    <Alert variant="warning">
                        Something completely unexpected happened when you tried
                        to connect to your Strava account. Please try again
                        later. If the problem persists, please contact us.
                    </Alert>
                    <Row style={{ height: 12 }}></Row>
                    <Link to="/strava/connect">
                        <Button>Try again</Button>
                    </Link>
                    <Row style={{ height: 12 }}></Row>
                    <Link to="/home">
                        <Button variant="light">Skip for now</Button>
                    </Link>
                </Col>
            );
    }

    switch (stravaExchangeCustomError) {
    }

    return (
        <div className="StravaExchangeToken">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Giving the Distance | Strava Link Confirmation</title>
            </Helmet>
            <Row>
                <Row style={{ height: 12 }}></Row>
                <h3>Strava Link Confirmation</h3>
                {codeSpecificComponent}
            </Row>
        </div>
    );
}

export default StravaExchangeToken;
