import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useLocation, Link } from "react-router-dom";
import useAuth from "../../components/AuthContext";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import StravaButton from "../../media/btn_strava_connectwith_light@2x.png";

import "./ConnectStrava.css";

function ConnectStrava() {
    const navigate = useNavigate();
    const { loadingCurrentUser, currentUser } = useAuth();
    console.log("currentUser", currentUser);

    const serverURL = "https://beta.api.givingthedistance.org";
    const serverRedirectPath = `/strava/exchange_token?approval_prompt=force&scope=activity:read,activity:write`;
    const userID = currentUser ? currentUser["cognito:username"] : "";
    const stravaConnectURL = `https://www.strava.com/oauth/authorize?state=${userID}&client_id=101361&response_type=code&redirect_uri=${serverURL}${serverRedirectPath}`;

    return (
        <div className="ConnectStrava">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Giving the Distance | Connect Strava</title>
            </Helmet>
            <Row>
                <Row style={{ height: 12 }}></Row>
                <h3>Connect with Strava</h3>
                {loadingCurrentUser ? (
                    <Row>
                        <Col>
                            <p>Loading...</p>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col>
                            <p>
                                Press the button below to link your Strava™
                                account with Giving the Distance™.
                            </p>
                            <p>
                                <a href={stravaConnectURL}>
                                    <img
                                        src={StravaButton}
                                        alt="Connect with Strava"
                                        id="connect-strava-button"
                                    />
                                </a>
                            </p>
                            <p>
                                Here's why you will be asked to provide the
                                following permissions:
                            </p>

                            <ul>
                                <li>
                                    <i>
                                        View data about your public profile
                                        (required)
                                    </i>
                                    <strong>
                                        <ul>
                                            <li>
                                                The application needs this in
                                                order to know which activity
                                                belongs to whom.
                                            </li>
                                        </ul>
                                    </strong>
                                </li>
                                <li>
                                    <i>View data about your activities</i>
                                    <strong>
                                        <ul>
                                            <li>
                                                The application needs this in
                                                order to see your activities.
                                            </li>
                                            <li>
                                                Activities saved as "Only you"
                                                will <i>NOT</i> be shared with
                                                the application.
                                            </li>
                                        </ul>
                                    </strong>
                                </li>
                                <li>
                                    <i>
                                        Upload your activities from Giving the
                                        Distance (Development) to Strava
                                    </i>
                                    <strong>
                                        <ul>
                                            <li>
                                                The application needs this in
                                                order to update the description
                                                of your Activities.
                                            </li>
                                        </ul>
                                    </strong>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                )}
            </Row>
        </div>
    );
}

export default ConnectStrava;
