import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { signUp } from "../../../utils/cognitoAuth";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import RedirectNotificationComponent from "../../../components/RedirectNotificationComponent";

import "./SignUp.css";

function SignUp() {
    const navigate = useNavigate();

    const [validated, setValidated] = useState(false);

    const [signUpEmail, setSignUpEmail] = useState("");
    const [signUpPassword, setSignUpPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [signUpError, setSignUpError] = useState("");

    const handleSubmit = async (event) => {
        console.log(signUpEmail, signUpPassword);
        event.preventDefault();
        event.stopPropagation();

        if (firstName === "") {
            setSignUpError("First name is required");
            return;
        }
        if (lastName === "") {
            setSignUpError("Last name is required");
            return;
        }

        const signUpResult = await signUp(
            signUpEmail,
            signUpPassword,
            firstName,
            lastName
        )
            .then((result) => {
                console.log(result);
                navigate("/signupverify?email=" + signUpEmail);
            })
            .catch((err) => {
                console.log(err);
                if (
                    err?.name === "AuthError" ||
                    err?.name === "NotAuthorizedException" ||
                    err?.name === "UserNotFoundException" ||
                    err?.name === "UserNotConfirmedException" ||
                    err?.name === "InvalidPasswordException" ||
                    err?.name === "UsernameExistsException"
                ) {
                    setSignUpError(err.message.replace("Username", "Email"));
                }
            });

        setValidated(true);
    };

    return (
        <div className="SignUp">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Giving the Distance | Sign up</title>
            </Helmet>
            <Container>
                <Row style={{ height: 12 }}></Row>
                <h3>Get signed up</h3>
                <Alert variant="info">
                    Signing up allows you to connect your Strava profile, start
                    sponsoring your friends, and track your donations.
                    <hr />
                    Your email is only used to sign in and verify your account,
                    so don't worry... we'll never share your information with{" "}
                    <i>anyone</i>.
                </Alert>
                <RedirectNotificationComponent />
                <Row style={{ height: 12 }}></Row>
                <Row>
                    <Form
                        validated={validated}
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <Row className="mb-3">
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationCustomFirstName"
                            >
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Ricky"
                                    defaultValue=""
                                    onChange={(event) => {
                                        setFirstName(event.target.value);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationCustomLastName"
                            >
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                    required
                                    type="Last name"
                                    placeholder="Bobby"
                                    defaultValue=""
                                    onChange={(event) => {
                                        setLastName(event.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationCustomEmail"
                            >
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="rickybobby@example.com"
                                    defaultValue=""
                                    onChange={(event) => {
                                        setSignUpEmail(event.target.value);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationCustom02"
                            >
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    placeholder="Create a password"
                                    defaultValue=""
                                    onChange={(event) => {
                                        setSignUpPassword(event.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Row>
                        <Col>
                            <Alert variant="info">
                                Your password must contain at least 8
                                characters, 1 uppercase letter, 1 lowercase
                                letter, 1 number, and 1 special character.
                            </Alert>
                        </Col>
                        <Button type="submit">Sign up</Button>
                        <Row style={{ height: 12 }}></Row>
                        <Row className="mb-3">
                            <Link to="/signin">
                                Already a user? Sign in here!
                            </Link>
                        </Row>
                        {signUpError ? (
                            <>
                                <Row style={{ height: 12 }}></Row>
                                <Row className="mb-3">
                                    <Alert variant={"danger"}>
                                        {signUpError}
                                    </Alert>
                                </Row>
                            </>
                        ) : null}
                    </Form>
                </Row>
            </Container>
        </div>
    );
}

export default SignUp;
